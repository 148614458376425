import styled from "styled-components"

export const StyledWrapper = styled.nav`
  
  font-family: ${({ theme }) => theme.font.family.fira};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: sticky; top: 0;
  background-color: white;
  z-index: 1000;
  width: 100%;
  height: 80px;
  border-bottom:1px solid ${({ theme }) => theme.color.blue};

  ${({ theme }) => theme.mq.desktop} {
    
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: 100px;
    border-bottom: none;
  }

  img {
    margin-bottom: 0; 
    height: 50px;
    ${({ theme }) => theme.mq.desktop} {
      height: auto;
    }
  }
`

export const StyledList = styled.ul`
  list-style: none;
  display: none;
  flex-direction: row;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
  }
`

export const StyledListItem = styled.li`
  font-size: ${({ theme }) => theme.font.size["21"]};
  color: black;
  margin-right: 15px;
  text-transform: uppercase;

  a {
    text-decoration: none;
    font-weight: bold;
    padding: 5px;
    color: inherit;
    transition: all ease-in-out 200ms;

    &:hover {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`
