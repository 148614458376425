import React, {useState} from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { StyledList, StyledListItem, StyledWrapper } from "./styled"
import { routes } from "../../../content/routes"
import MobileMenuModal from './MobieMenuModal'
import { StyledMenuIcon } from './styled'
import ElmexLogo from "../../../images/logo-black.png"

const Menu = () => {

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  return (
    <StyledWrapper>
      <Link to="/">
        <img src={ElmexLogo} alt="" />
      </Link>

      <StyledList>
        {routes &&
          routes.map(route => (
            <StyledListItem key={route.id}>
              <a href={route.link}>{route.name}</a>
            </StyledListItem>
          ))}
      </StyledList>

      {!isMenuOpened ? (
          <StyledMenuIcon onClick={() => setIsMenuOpened(!isMenuOpened)}>
            <FontAwesomeIcon color="#3777bc" icon={faBars} size="3x" />
          </StyledMenuIcon>
          ) : null 
        }


      <MobileMenuModal isMenuOpened={isMenuOpened} handleMenuOpen={setIsMenuOpened} routes={routes} />
    </StyledWrapper>
  )
}

export default Menu
