import React from "react"
import { routes } from '../../../content/routes'

import { FooterWrapper, Copyright, FooterMenuList, FooterInfo } from "./styled"

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterInfo>
        <Copyright> © {new Date().getFullYear()} | Elmex - Piotr Palka</Copyright>
      </FooterInfo>
      <FooterMenuList>
        {routes &&
            routes.map(route => (
              <li key={route.id}>
                <a href={route.link}>{route.name}</a>
              </li>
            ))}
      </FooterMenuList>
    </FooterWrapper>
  )
}

export default Footer
