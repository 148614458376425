import styled from "styled-components"
import { motion } from "framer-motion"

export const StyledHamburgerIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: ${({ theme }) => theme.color.blue};
  margin-bottom: 5px;
`
export const StyledMobileMenuWrapper = styled.nav`
  display: flex;
  padding: 10px;
  position: relative;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`

export const StyledMenuIcon = styled.div`
  position: absolute;
  z-index: 20;
  top: 25px;
  right: 45px;
  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

export const StyledMobileMenuList = styled(motion.div)`
  padding-top: 100px;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 60vw;
  height: 53vh;
  min-height: 410px;
  background-color: rgb(40,40,40);
  transition: all ease-in-out 0.5s;
  box-shadow: 10px 10px 42px 3px rgba(0, 0, 0, 0.19);

  ${({ theme }) => theme.mq.tablet} {
    width: 40vw;
  }


`

export const StyledMobileMenuItem = styled.p`
  color: white;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 5px;
  a {
    display: block;
    padding: 2rem;
    width: 100%;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
  }
`;