const theme = {
  color: {
    yellow: "#ffcb05",
    yellowDarker: "#CCA200",
    yellowLighten: "#FFD534",
    blue: "#3777bc",
    white: "#fff",
    black: "#000",
  },
  font: {
    family: {
      fira: `'Fira Sans', sans-serif`,
    },
    size: {
      "16": "1.6rem",
      "21": "2.1rem",
      "26": "2.6rem",
      "30": "3rem",
      "36": "3.6rem",
      "40": "4rem",
      "50": "5rem",
    },
  },
  mq: {
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    huge: `@media(min-width: 1440px)`,
  },
  other: {
    gap: "45px",
  },
}

export default theme
