import React, {useRef, useEffect} from "react"
import { AnimatePresence } from "framer-motion"
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import { StyledMobileMenuList, StyledMobileMenuItem } from "./styled"

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    duration: 0.05,
    ease: "easeInOut",
  },
  key: "child",
}

const useOutsideClick = (ref, cb) => {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              cb();
          }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref, cb]);
};


export const MenuIcon = styled.div`
  position: absolute;
  z-index: 20;
  top: 45px;
  right: 48px;
`;

const MobieMenuModal = ({ isMenuOpened, handleMenuOpen, routes }) => {

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => handleMenuOpen(!isMenuOpened))

  return (
    <AnimatePresence>
      {isMenuOpened && (
        <StyledMobileMenuList ref={wrapperRef} {...animation} key="menuModal">
          
          <MenuIcon onClick={() => handleMenuOpen(!isMenuOpened)}>
            <FontAwesomeIcon color="white" icon={faTimes} size="3x" />
          </MenuIcon>
          
          
          {routes &&
            routes.map(route => (
              <StyledMobileMenuItem key={route.id}>
                <a href={route.link}>{route.name}</a>
              </StyledMobileMenuItem>
            ))}
        </StyledMobileMenuList>
      )}
    </AnimatePresence>
  )
}

export default MobieMenuModal
