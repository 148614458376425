import styled from "styled-components"

export const FooterWrapper = styled.footer`
  padding: 20px;
  font-size: ${({ theme }) => theme.font.size["16"]};
  background: linear-gradient(45deg, hsl(211deg 55% 35%), hsl(211deg 55% 52%)); 
  color: white;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  
  ${({theme}) => theme.mq.desktop} {
    flex-direction: row;
  } 
`

export const Copyright = styled.p`
  text-align: center;
  padding-bottom: 25px;
`
export const GatsbyText = styled.p`
  font-size: 1rem;
  text-align: center;

  a {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
`

export const FooterInfo = styled.div``;

export const FooterMenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  width: auto;

  ${({theme}) => theme.mq.desktop} {
    width: 50%;
  }

  li {

    padding-right: 5px;
    

    a {
      font-size: 0.8em;
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;

      ${({theme}) => theme.mq.desktop} {
        font-size: 1em;
      }
    }
  }

`