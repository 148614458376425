/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./partial/Footer/Footer"
import Menu from "./partial/Menu/Menu"
import "../styles/layout.scss"
import "../styles/global.scss"
import theme from "../styles/theme"
import { ThemeProvider } from "styled-components"

const Layout = ({ children }) => (
    <StaticQuery
        query={ graphql` query SiteTitleQuery { site { siteMetadata { title } } } `}
        render={ data => (

          <ThemeProvider theme={ theme }>
              <>
                  <Menu/>
                  <div>
                      <main>{children}</main>
                  </div>
                  <Footer/>
              </>
          </ThemeProvider>
        ) }
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;